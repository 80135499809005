import React from 'react';
import './App.css';
import Typography from "@material-ui/core/Typography";
import Projects from "./Components/Projects";
import Articles from "./Components/Articles";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <Typography variant="h4" style={{paddingTop: 15}}>projects</Typography>
                <Projects/>
                <br/>
                <Typography variant="h4" style={{paddingTop: 15}}>articles</Typography>
                <Articles/>
            </header>
        </div>
    );
}

export default App;
